.lex-site-header {
  display: grid;
  grid-template: "logo login" auto
                 "search search" auto
                / 1fr max-content;
  gap: var(--spacing1) var(--gutter);
  align-items: center;
}

.lex-site-header--dark-background {
  --outline-color: white;
}

.lex-site-header__frontpage-link {
  grid-area: logo;
  color: inherit;
}

.lex-site-header__frontpage-link:link,
.lex-site-header__frontpage-link:visited {
  border-bottom-style: none;
}

.lex-site-header__login {
  grid-area: login;
}

.lex-site-header__search-form {
  grid-area: search;
  width: 100%;
}

@media (--large-up) {
  .lex-site-header {
    padding: 0 var(--gutter);
    position: relative;
    grid-template: "logo search login"
                  / 50% 1fr max-content;
    gap: 0;
  }

  .lex-site-header__frontpage-link {
    grid-area: logo;
    max-width: 300px;
  }

  .lex-site-header__login {
    order: 1;
    grid-area: login;
    margin-left: 40px;
  }

  .lex-site-header__search-form {
    padding-left: var(--half-gutter);
  }
}
