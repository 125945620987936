.l-lex-front__section {
  padding-block: 24px;
}

.l-lex-front__section--featured {
  background: white;
}

.l-lex-front__section--encyclopedias {
  background: var(--color-pampas);
}

.l-lex-front__read-stats {
  display: grid;
  grid-template-columns: 100%;
  gap: var(--gutter);
}

@media (--medium-up) {
  .l-lex-front__read-stats {
    padding-inline: var(--half-gutter);
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (--large-up) {
  .l-lex-front__section {
    padding-block: 50px;
  }

  .l-lex-front__read-stats {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
