.large-link-list__items {
  margin: 0;
  padding: 0;
}

.large-link-list__item {
  list-style: none;
  padding-top: 20px; /* Anticipating multicol variant, hence not margin or grid gap */
}

.large-link-list__category {
  font: var(--small-category);
  letter-spacing: 0.6px;
  padding: 2px 6px;
  background: var(--color-periwinkle);
}

.large-link-list__text {
  font: var(--front-large-link);
}

.large-link-list__link {
  border-bottom-style: none;
}

.large-link-list__link:hover {
  border-bottom-style: solid;
}

.large-link-list__description,
.large-link-list__time {
  font: var(--meta-small);
}

.large-link-list__time::before {
  content: " ◦ ";
}

@media (--medium-up) {
  .large-link-list--wide .large-link-list__items {
    columns: 2;
    gap: var(--gutter);
  }

  .large-link-list__item {
    break-inside: avoid;
  }
}

@media (--large-up) {
  .large-link-list__header {
    margin-bottom: 10px;
  }

  .large-link-list--wide .large-link-list__items {
    columns: 3;
  }
}
