.banner {
  background-color: var(--color-bizarre);
}

.banner__figure {
  margin: 0;
  height: 210px;
  position: relative;
}

.banner__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.banner__contents {
  text-align: center;
  padding: 20px 0;
}

.banner__heading {
  font: var(--font-banner-title);
  margin-bottom: 10px;
}

.banner__intro {
  font: var(--font-banner-intro);
  margin-bottom: 20px;
}

.banner__cta {
  margin: 0;
  font: var(--font-footer-details);
}

.banner__cta-link:not(:hover) {
  border-bottom-color: transparent;
}

.banner__cta-link::after {
  content: url('../images/arrow-right.svg');
  vertical-align: bottom;
  line-height: 1;
  margin-left: 12px;
}

@media (--medium-up) {
  .banner__contents {
    padding-inline: var(--half-gutter);
  }
}

@media (--medium-plus-up) {
  .banner {
    position: relative;
  }

  .banner__figure {
    position: absolute;
    top: 0;
    left: 0;
    right: 50%;
    margin-right: var(--half-gutter);
    height: 100%;
  }

  .banner__image {
    object-position: center right;
  }

  .banner__contents {
    margin-left: calc(5 / 10 * 100%);
  }
}

@media (--large-up) {
  .banner__figure {
    --grid-column: 72px;
    margin-right: calc(var(--grid-column) + var(--gutter) + var(--half-gutter));
  }

  .banner__contents {
    padding-top: 50px;
    padding-bottom: 60px;
    margin-left: calc(6 / 12 * 100%);
    margin-right: calc(1 / 12 * 100%);
  }

  .banner__heading {
    letter-spacing: 0.05em;
    font: var(--font-banner-title);
    margin-bottom: 30px;
  }

  .banner__intro {
    margin-bottom: 30px;
  }
}

@media (--xlarge-up) {
  .banner__figure {
    --grid-column: 85px;
  }
}

@media (--xlarge-plus-up) {
  .banner__figure {
    --grid-column: 103px;
  }
}
