.grouped-encyclopedias__heading {
  font: var(--front-subheading);
  margin-bottom: 0.75rem;
}

.grouped-encyclopedias__section:not(:last-child) {
  margin-bottom: 20px;
}

.grouped-encyclopedias__group-label {
  font: var(--small-category);
  letter-spacing: 0.6px;
  padding: 2px 6px;
  background: var(--color-gallery);
  width: max-content;
  margin-bottom: 20px;
}

.grouped-encyclopedias__list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.grouped-encyclopedias__item:not(:last-child) {
  margin-bottom: 20px;
}

.grouped-encyclopedias__item-link {
  font: var(--front-large-link);
}

.grouped-encyclopedias__item-link:not(:hover) {
  /* Avoid actually turning the border on/off on hover, since it affects column
   * height in a buggy way on Firefox. Toggling transparency instead.
   */
  border-bottom-color: transparent;
}

.grouped-encyclopedias__item-intro {
  font: var(--front-preamble);
  margin: 8px 0 0;
}

@media (--medium-up) {
  .grouped-encyclopedias__heading {
    margin: 0 var(--half-gutter) 30px;
  }

  .grouped-encyclopedias__sections {
    margin-inline: var(--half-gutter);
    columns: 2;
    gap: var(--gutter);
  }

  .grouped-encyclopedias__section {
    break-inside: avoid;
  }

  .grouped-encyclopedias__section:not(:last-child) {
    margin-bottom: 40px;
  }
}

@media (--large-up) {
  .grouped-encyclopedias__sections {
    columns: 3;
  }
}
