.newsletter-signup {
  background: var(--color-blue-ribbon);
  padding-inline: var(--half-gutter);
}

.newsletter-signup__iframe {
  border: none;
  width: 100%;
  height: 450px;
}

@media (--medium-up) {
  .newsletter-signup__iframe {
    height: 320px;
  }
}

@media (--large-up) {
  .newsletter-signup {
    padding-block: 20px;
  }
}
