.encyclopedia-intro {
  color: black;
  background: var(--color-nobel);
  padding-bottom: var(--spacing2);
}

.encyclopedia-intro--main-encyclopedia {
  color: white;
  background: var(--color-blue-ribbon);
}

.encyclopedia-intro__lead,
.encyclopedia-intro__description {
  margin: 0;
}

.encyclopedia-intro__lead {
  font: 28px/130% "Inter", sans-serif;
}

.encyclopedia-intro__description {
  font: 28px/130% "Publico text", serif;
  font-variant-numeric: var(--numeric)
}

@media (--medium-up) {
  .encyclopedia-intro__lead,
  .encyclopedia-intro__description {
    margin-inline: var(--half-gutter);
  }
}

@media (--large-up) {
  .encyclopedia-intro__lead,
  .encyclopedia-intro__description {
    font-size: 32px;
  }
}
