.l-pre-footer::before {
  content: "";
  display: block;
  border-top: 1px solid;
  margin: 0 var(--half-gutter);
}

.l-pre-footer__updates {
  padding: 24px 0;
}

.l-pre-footer__common-section {
  padding-block: 24px;
  margin-top: 24px;
  background: white;
}

@media (--medium-up) {
  .l-pre-footer::before {
    width: calc(var(--grid-width) - 2 * var(--gutter));
    margin: 0 auto;
  }
}

@media (--large-up) {
  .l-pre-footer__updates {
    padding-block: 50px;
  }

  .l-pre-footer__common-section {
    padding-block: 50px;
    margin-top: 50px;
  }
}
